<template>
  <base-drop-down-menu-button>
    <template v-slot:iconName>
      {{ iconProjects }}
    </template>
    Project
    <template v-slot:commands>
      <!-- Export Project -->
      <base-menu-item
        v-if="visibleExportProject"
        :disabled="!enabledExportProject"
        @click="onExport"
      >
        <template v-slot:iconName>
          {{ exportProjectIcon }}
        </template>
        <template v-slot:tooltipContent>
          <div>
            {{ exportProjectTooltip }}
          </div>
        </template>
        {{ exportProjectLabel }}
      </base-menu-item>

      <!-- Assign Manager -->
      <base-menu-item
        v-if="visibleAssignProjectManager"
        :disabled="!enabledAssignProjectManager"
        @click="onAssignManager"
      >
        <template v-slot:iconName>
          {{ assignProjectManagerIcon }}
        </template>
        <template v-slot:tooltipContent>
          <div>
            {{ assignProjectManagerTooltip }}
          </div>
        </template>
        {{ assignProjectManagerLabel }}
      </base-menu-item>

      <!-- Resume Project -->
      <base-menu-item
        v-if="visibleResumeProject"
        :disabled="!enabledResumeProject"
        @click="onResume"
      >
        <template v-slot:iconName>
          {{ resumeProjectIcon }}
        </template>
        <template v-slot:tooltipContent>
          <div>
            {{ resumeProjectTooltip }}
          </div>
        </template>
        {{ resumeProjectLabel }}
      </base-menu-item>

      <!-- Delete Project -->
      <base-menu-item
        v-if="visibleDeleteProject"
        :disabled="!enabledDeleteProject"
        @click="onDelete"
      >
        <v-icon :color="deleteColorClass">
          {{ deleteProjectIcon }}
        </v-icon>
        <template v-slot:tooltipContent>
          <div>
            {{ deleteProjectTooltip }}
          </div>
        </template>
        {{ deleteProjectLabel }}
      </base-menu-item>

      <!-- Cancel Project -->
      <base-menu-item
        v-if="visibleCancelProject"
        :disabled="!enabledCancelProject"
        @click="onCancel"
      >
        <v-icon :color="deleteColorClass">
          {{ cancelProjectIcon }}
        </v-icon>
        <template v-slot:tooltipContent>
          <div>
            {{ cancelProjectTooltip }}
          </div>
        </template>
        {{ cancelProjectLabel }}
      </base-menu-item>

      <!-- Suspend Project -->
      <base-menu-item
        v-if="visibleSuspendProject"
        :disabled="!enabledSuspendProject"
        @click="onSuspend"
      >
        <v-icon :color="deleteColorClass">
          {{ suspendProjectIcon }}
        </v-icon>
        <template v-slot:tooltipContent>
          <div>
            {{ suspendProjectTooltip }}
          </div>
        </template>
        {{ suspendProjectLabel }}
      </base-menu-item>

      <!-- repair Project -->
      <base-menu-item
        v-if="visibleRepairProject"
        :disabled="!enabledRepairProject"
        @click="onRepairProject"
      >
        <v-icon>
          {{ repairProjectIcon }}
        </v-icon>
        <template v-slot:tooltipContent>
          <div>
            {{ repairProjectTooltip }}
          </div>
        </template>
        {{ repairProjectLabel }}
      </base-menu-item>

      <slot name="actions"></slot>
    </template>
  </base-drop-down-menu-button>
</template>

<script>
// design
import {
  iconAccount,
  iconAccountArrowRight,
  iconCancel,
  iconClear,
  iconEdit,
  iconLock,
  iconPlay,
  iconProjects,
  iconRun,
  iconSuspend,
  iconTrash
} from "@/design/icon/iconConst";

// model
import {
  findProjectOperation,
  projectOperation
} from "@/model/workflow/project/projectModel";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";
import {
  cancelProjectCommandStatus,
  changeOwnershipProjectCommandStatus,
  deleteProjectCommandStatus,
  resumeProjectCommandStatus,
  suspendProjectCommandStatus
} from "@/services/project/projectService";
import { commandStatus } from "@/model/common/commands/commandModel";

export default {
  name: "ProjectActions",
  mixins: [menuItemSettingsMixin, buttonSettingsMixin, deleteSettingsMixin],
  components: {
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem")
  },
  data() {
    return {
      iconTrash: iconTrash,
      iconEdit: iconEdit,
      iconLock: iconLock,
      iconClear: iconClear,
      iconResume: iconRun,
      iconAccount: iconAccount,
      iconProjects: iconProjects,

      iconPlay: iconPlay,
      iconCancel: iconCancel,
      iconSuspend: iconSuspend,
      iconAccountArrowRight: iconAccountArrowRight
    };
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: Number, projectName:string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}}
     */
    project: undefined,

    /**
     * Export a current workflow project
     */
    onExport: {
      type: Function,
      required: false
    },

    /**
     * Export a current workflow project
     */
    onAssignManager: {
      type: Function,
      required: false
    },

    /**
     * Resume a current workflow project
     */
    onResume: {
      type: Function,
      required: false
    },

    /**
     * Delete a current workflow project
     */
    onDelete: {
      type: Function,
      required: false
    },

    /**
     * Cancel a current workflow project
     */
    onCancel: {
      type: Function,
      required: false
    },

    /**
     * Suspend a current workflow project
     */
    onSuspend: {
      type: Function,
      required: false
    },

    /**
     * Repair provided workflow Project
     */
    onRepairProject: {
      type: Function,
      required: false
    }
  },
  computed: {
    /**
     * can current user Access project
     * @return {boolean} true if current user can Access a project
     */
    canAccess() {
      return this.project?.canAccess ?? false;
    },

    /**
     * export Project Label
     * @return {string}
     */
    exportProjectLabel() {
      return findProjectOperation(projectOperation.ExportProject)?.label;
    },

    /**
     * export Project Icon
     * @return {string}
     */
    exportProjectIcon() {
      return findProjectOperation(projectOperation.ExportProject)?.icon;
    },

    /**
     * visible Export Project
     * @return {boolean}
     */
    visibleExportProject() {
      return this.canAccess;
    },

    /**
     * enabled Export Project
     * @return {boolean}
     */
    enabledExportProject() {
      return this.canAccess;
    },

    /**
     * assign Project Manager Label
     * @return {string}
     */
    assignProjectManagerLabel() {
      return findProjectOperation(projectOperation.ReassignManager)?.label;
    },

    /**
     * assign Project Manager Icon
     * @return {string}
     */
    assignProjectManagerIcon() {
      return findProjectOperation(projectOperation.ReassignManager)?.icon;
    },

    /**
     * visible Assign Project Manager
     * @return {boolean}
     */
    visibleAssignProjectManager() {
      return (
        changeOwnershipProjectCommandStatus(this.project).status !==
        commandStatus.unavailable
      );
    },

    /**
     * enabled Assign Project Manager
     * @return {boolean}
     */
    enabledAssignProjectManager() {
      return (
        changeOwnershipProjectCommandStatus(this.project).status ===
        commandStatus.enabled
      );
    },

    /**
     * Resume Project Label
     * @return {string}
     */
    resumeProjectLabel() {
      return findProjectOperation(projectOperation.ResumeProject)?.label;
    },

    /**
     * Resume Project icon
     * @return {string}
     */
    resumeProjectIcon() {
      return findProjectOperation(projectOperation.ResumeProject)?.icon;
    },

    /**
     * visible Resume Project
     * @return {boolean}
     */
    visibleResumeProject() {
      return (
        resumeProjectCommandStatus(this.project).status !==
        commandStatus.unavailable
      );
    },

    /**
     * enabled Resume Project
     * @return {boolean}
     */
    enabledResumeProject() {
      return (
        resumeProjectCommandStatus(this.project).status ===
        commandStatus.enabled
      );
    },

    /**
     * delete Project Label
     * @return {string}
     */
    deleteProjectLabel() {
      return findProjectOperation(projectOperation.DeleteProject)?.label;
    },

    /**
     * Resume Project icon
     * @return {string}
     */
    deleteProjectIcon() {
      return findProjectOperation(projectOperation.DeleteProject)?.icon;
    },

    /**
     * visible Delete Project
     * @return {boolean}
     */
    visibleDeleteProject() {
      return (
        deleteProjectCommandStatus(this.project).status !==
        commandStatus.unavailable
      );
    },

    /**
     * enabled Delete Project
     * @return {boolean}
     */
    enabledDeleteProject() {
      return (
        deleteProjectCommandStatus(this.project).status ===
        commandStatus.enabled
      );
    },

    /**
     * cancel Project Label
     * @return {string}
     */
    cancelProjectLabel() {
      return findProjectOperation(projectOperation.CancelProject)?.label;
    },

    /**
     * cancel Project icon
     * @return {string}
     */
    cancelProjectIcon() {
      return findProjectOperation(projectOperation.CancelProject)?.icon;
    },

    /**
     * visible Cancel Project
     * @return {boolean}
     */
    visibleCancelProject() {
      return (
        cancelProjectCommandStatus(this.project).status !==
        commandStatus.unavailable
      );
    },

    /**
     * enabled Cancel Project
     * @return {boolean}
     */
    enabledCancelProject() {
      return (
        cancelProjectCommandStatus(this.project).status ===
        commandStatus.enabled
      );
    },

    /**
     * suspend Project Label
     * @return {string}
     */
    suspendProjectLabel() {
      return findProjectOperation(projectOperation.SuspendProject)?.label;
    },

    /**
     * suspend Project icon
     * @return {string}
     */
    suspendProjectIcon() {
      return findProjectOperation(projectOperation.SuspendProject)?.icon;
    },

    /**
     * visible Suspend Project
     * @return {boolean}
     */
    visibleSuspendProject() {
      return (
        suspendProjectCommandStatus(this.project).status !==
        commandStatus.unavailable
      );
    },

    /**
     * enabled Suspend Project
     * @return {boolean}
     */
    enabledSuspendProject() {
      return (
        suspendProjectCommandStatus(this.project).status ===
        commandStatus.enabled
      );
    },

    /**
     * repair Project Label
     * @return {string}
     */
    repairProjectLabel() {
      return findProjectOperation(projectOperation.SuspendProject)?.label;
    },

    /**
     * repair Project icon
     * @return {string}
     */
    repairProjectIcon() {
      return findProjectOperation(projectOperation.SuspendProject)?.icon;
    },

    /**
     * visible Repair Project
     * @return {boolean}
     */
    visibleRepairProject() {
      return !(this.project?.isValid ?? false);
    },

    /**
     * enabled Repair Project
     * @return {boolean}
     */
    enabledRepairProject() {
      return !(this.project?.isValid ?? false);
    },

    /**
     *  Export Project Tooltip
     * @return {*}
     */
    exportProjectTooltip() {
      return findProjectOperation(projectOperation.ExportProject)?.hint;
    },

    /**
     *  Assign Project Manager Tooltip
     * @return {*}
     */
    assignProjectManagerTooltip() {
      return findProjectOperation(projectOperation.ReassignManager)?.hint;
    },

    /**
     *  Resume Project Tooltip
     * @return {*}
     */
    resumeProjectTooltip() {
      return findProjectOperation(projectOperation.ResumeProject)?.hint;
    },

    /**
     *  Delete Project Tooltip
     * @return {*}
     */
    deleteProjectTooltip() {
      return findProjectOperation(projectOperation.DeleteProject)?.hint;
    },

    /**
     *  Cancel Project Tooltip
     * @return {*}
     */
    cancelProjectTooltip() {
      return findProjectOperation(projectOperation.CancelProject)?.hint;
    },

    /**
     *  Suspend Project Tooltip
     * @return {*}
     */
    suspendProjectTooltip() {
      return findProjectOperation(projectOperation.SuspendProject)?.hint;
    },

    /**
     *  Repair Project Tooltip
     * @return {*}
     */
    repairProjectTooltip() {
      return findProjectOperation(projectOperation.RepairProject)?.hint;
    }
  }
};
</script>

/**
 * Material Design Spacing Type
 *
 * @typedef {Object<string, string>} spacingType
 *
 * @property {string} margin applies margin
 * @property {string} padding applies padding
 */
/**
 * Spacing Type
 * @type {spacingType}
 */
const spacingType = Object.freeze({
  margin: "m",
  padding: "p"
});

/**
 * Material Design Spacing size
 *
 * @typedef {Object<string, string, string>} size
 *
 * @property {string} size0 eliminates all margin or padding by setting it to 0
 * @property {string} size1 sets margin or padding to 4px
 * @property {string} size2 sets margin or padding to 8px
 * @property {string} size3 sets margin or padding to 12px
 * @property {string} size4 sets margin or padding to 16px
 * @property {string} size5 sets margin or padding to 20px
 * @property {string} size6 sets margin or padding to 24px
 * @property {string} size7 sets margin or padding to 28px
 * @property {string} size8 sets margin or padding to 32px
 * @property {string} size9 sets margin or padding to 36px
 * @property {string} size10 sets margin or padding to 40px
 * @property {string} size11 sets margin or padding to 44px
 * @property {string} size12 sets margin or padding to 48px
 * @property {string} size13 sets margin or padding to 52px
 * @property {string} size14 sets margin or padding to 56px
 * @property {string} size15 sets margin or padding to 60px
 * @property {string} size16 sets margin or padding to 64px
 * @property {string} n1 sets margin to -4px
 * @property {string} n2 sets margin to -8px
 * @property {string} n3 sets margin to -12px
 * @property {string} n4 sets margin to -16px
 * @property {string} n5 sets margin to -20px
 * @property {string} n6 sets margin to -24px
 * @property {string} n7 sets margin to -28px
 * @property {string} n8 sets margin to -32px
 * @property {string} n9 sets margin to -36px
 * @property {string} n10 sets margin to -40px
 * @property {string} n11 sets margin to -44px
 * @property {string} n12 sets margin to -48px
 * @property {string} n13 sets margin to -52px
 * @property {string} n14 sets margin to -56px
 * @property {string} n15 sets margin to -60px
 * @property {string} n16 sets margin to -64px
 * @property {string} auto sets the spacing to auto
 */
/**
 * The size controls the increment of the property in 4px intervals
 * @type {size}
 */
const size = Object.freeze({
  size0: "0",
  size1: "1",
  size2: "2",
  size3: "3",
  size4: "4",
  size5: "5",
  size6: "6",
  size7: "7",
  size8: "8",
  size9: "9",
  size10: "10",
  size11: "11",
  size12: "12",
  size13: "13",
  size14: "14",
  size15: "15",
  size16: "16",
  n1: "n1",
  n2: "n2",
  n3: "n3",
  n4: "n4",
  n5: "n5",
  n6: "n6",
  n7: "n7",
  n8: "n8",
  n9: "n9",
  n10: "n10",
  n11: "n11",
  n12: "n12",
  n13: "n13",
  n14: "n14",
  n15: "n15",
  n16: "n16",
  auto: "auto"
});

/**
 * Material Design direction
 *
 * @typedef {Object<string, string, string, string, string, string, string, string, string>} direction
 *
 * @property {string} top applies the spacing for margin-top and padding-top
 * @property {string} bottom applies the spacing for margin-bottom and padding-bottom
 * @property {string} left applies the spacing for margin-left and padding-left
 * @property {string} right applies the spacing for margin-right and padding-right
 * @property {string} leftRight applies the spacing for margin-left/padding-left (in LTR mode) and margin-right/padding-right (in RTL mode)
 * @property {string} rightLeft applies the spacing for margin-right/padding-right (in LTR mode) and margin-left/padding-left (in RTL mode)
 * @property {string} leftAndRight applies the spacing for both *-left and *-right
 * @property {string} topAndBottom applies the spacing for both *-top and *-bottom
 * @property {string} all applies the spacing for the property in all directions
 */
/**
 * The direction designates the side the property applies to:
 * @type {direction}}
 */
const direction = Object.freeze({
  top: "t",
  bottom: "b",
  left: "l",
  right: "r",
  leftRight: "s",
  rightLeft: "e",
  leftAndRight: "x",
  topAndBottom: "y",
  all: "a"
});

/**
 * Material Design Breakpoints
 *
 * @typedef {Object<string, string, string, string, string>} Breakpoints
 *
 * @property {string} xs Small to large phone
 * @property {string} sm Small to medium tablet
 * @property {string} md Large tablet to laptop
 * @property {string} lg Desktop
 * @property {string} xl 4k and ultra-wide
 */

/**
 * @type {Breakpoints} breakpoints
 */
const breakpoints = Object.freeze({
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl"
});

/**
 * get Margin Class
 * @param {string} direction
 * @param {string} size
 * @returns {string}
 */
const getMarginClass = (direction, size) =>
  new Spacing(spacingType.margin, direction, size).getClassSpacing();

/**
 * get Padding Class
 * @param {string} direction
 * @param {string} size
 * @returns {string}
 */
const getPaddingClass = (direction, size) =>
  new Spacing(spacingType.padding, direction, size).getClassSpacing();

class Spacing {
  spacingType = spacingType.margin;
  direction = direction.all;
  size = size.size0;
  breakpoint = undefined;

  /**
   * Spacing constructor
   * @param {String|string} spacingType
   * @param {String|string} direction
   * @param {String|String} size
   */
  constructor(
    spacingType = spacingType.margin,
    direction = direction.all,
    size = size.auto
  ) {
    this.spacingType = spacingType;
    this.direction = direction;
    this.size = size;
  }

  /**
   * Get Class Spacing
   * Which applies spacing using Material Design spacings format
   * @returns {String|string}
   */
  getClassSpacing() {
    return this.breakpoint
      ? `${this.spacingType}${this.direction}-${this.breakpoint}-${this.size}`
      : `${this.spacingType}${this.direction}-${this.size}`;
  }
}

export {
  spacingType,
  size,
  direction,
  breakpoints,
  Spacing,
  getMarginClass,
  getPaddingClass
};

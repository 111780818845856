// design
import { Color } from "@/design/colors/Color";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

/**
 * Delete Settings Mixin encapsulate delete Settings logic
 */
export const deleteSettingsMixin = {
  computed: {
    ...mapGetters({
      deleteColor: "deleteColor"
    }),
    /**
     * Get Delete color (implementation of Material Design)
     * @returns {Color}
     */
    mdDeleteColor() {
      return new Color(
        this.deleteColor.name,
        this.deleteColor.variantType,
        this.deleteColor.variantNumber
      );
    },
    /**
     * Get Delete color class
     * @returns {string|*}
     */
    deleteColorClass() {
      return this.mdDeleteColor.getClassColor();
    }
  }
};

// design
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { Text } from "@/design/text/Text";
import {
  direction,
  size,
  Spacing,
  spacingType
} from "@/design/spacing/Spacing";

// utils
import { isEmpty } from "@/utils";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

// mixins
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

/**
 * Button Settings Mixin encapsulate common functionality of components which leveraging v-btn
 */
export const buttonSettingsMixin = {
  mixins: [publicAccessMixin],
  data() {
    return {
      spacingDirection: direction
    };
  },
  computed: {
    ...mapGetters({
      buttonColor: "buttonColor",
      buttonTextStyleSetting: "buttonTextStyle",
      buttonOutlinedSetting: "buttonOutlined",
      buttonRoundedSetting: "buttonRounded",
      buttonElevationSetting: "buttonElevation"
    }),

    /**
     * determines whether to make the background transparent based on current settings
     * Note: When using the color prop, the color will be applied to the button text instead of the background.
     * @return {boolean|boolean} if true then Makes the background transparent
     */
    buttonTextStyle() {
      return this.isPublicAccess
        ? this.publicAccess?.buttons?.textStyle ?? true
        : this.buttonTextStyleSetting;
    },

    /**
     * determines whether to make the Outlined button based on current settings
     * @return {boolean|boolean} if true Makes the background transparent and applies a thin border.
     */
    buttonOutlined() {
      return this.isPublicAccess
        ? this.publicAccess?.buttons?.outlined ?? false
        : this.buttonOutlinedSetting;
    },

    /**
     * determines whether to make the Rounded button based on current settings
     * @return {boolean|boolean} if true Applies a large border radius on the button.
     */
    buttonRounded() {
      return this.isPublicAccess
        ? this.publicAccess?.buttons?.rounded ?? false
        : this.buttonRoundedSetting;
    },

    /**
     * determines whether to make the Elevation button based on current settings
     * @return {number|Number} if true Designates an elevation applied to the component between 0 and 24. You can find more information on the elevation page.
     */
    buttonElevation() {
      return this.isPublicAccess
        ? this.publicAccess?.buttons?.elevation ?? 0
        : this.buttonElevationSetting ?? 0;
    },

    /**
     * Get button Material Design color
     * @returns {Color}
     */
    mdButtonColor() {
      return new Color(
        this.buttonColor.name,
        this.buttonColor.variantType,
        this.buttonColor.variantNumber
      );
    },

    /**
     * Get button Color Class
     * @returns {string|*}
     */
    buttonColorClass() {
      if (this.isPublicAccess) {
        const color = this.publicAccess?.buttons?.color;
        return isEmpty(color) ? this.mdButtonColor.getClassColor() : color;
      }

      return this.mdButtonColor.getClassColor();
    },

    buttonRecommendedColorClass() {
      return this.buttonTextStyle
        ? undefined
        : new Text(
            new Color(colorMD.grey, variantType.lighten, variantNumber.n2)
          ).getTextColorClass();
    },

    /**
     * Get Recommended Spacing Class
     * @return {String|string}
     */
    buttonRecommendedSpacingClass() {
      return this.recommendedSpacingClass(direction.right);
    },

    /**
     * button Recommended Text Style
     * @return {false|Chai.Assertion|*|boolean}
     */
    buttonRecommendedTextStyle() {
      return this.buttonOutlined ? this.false : this.buttonTextStyle;
    }
  },
  methods: {
    /**
     * Get button's Recommended Class
     * @param {string} spacingDirection
     * @return {string}
     */
    buttonRecommendedClass(spacingDirection = undefined) {
      return spacingDirection
        ? `${this.recommendedSpacingClass(spacingDirection)} ${
            this.buttonRecommendedColorClass
          }`
        : this.buttonRecommendedColorClass;
    },
    /**
     * recommended Spacing Class
     * @param { string }  direction
     * @return {String|string}
     */
    recommendedSpacingClass(direction) {
      return new Spacing(
        spacingType.margin,
        direction,
        size.size2
      ).getClassSpacing();
    },

    /**
     * button Recommended Outlined
     * @param { boolean } outlined
     */
    buttonRecommendedOutlined(outlined = true) {
      return outlined ? !!this.buttonTextStyle : this.buttonOutlined;
    }
  }
};
